<script>
import Btn from '@/components/UI/Btn.vue';
import Input from '@/components/UI/Input.vue';

export default {
  components: { Btn, Input },
  name: "AddAddress",
  data() {
    return {
      city: '',
      street: '',
    };
  },
  methods: {
    async submitAddress() {
      try {
        const url = process.env.VUE_APP_BASE_URL + '/address' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            city: this.city,
            info: this.street,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to add address');
        }
        console.log('Address added successfully!');
        this.$router.go(-1);
      } catch (error) {
        console.error('Error adding address:', error);
      }
    },
  },
};
</script>

<template>
    <div>
        <div class="relative">
            <h3 class="text-center py-[29px] text-light-main dark:text-dark-main font-bold text-xl leading-6">Новый адрес</h3>
            <button @click="$router.go(-1)" class="absolute right-0 top-1/2 translate-y-[-50%] w-10 h-10 flex items-center justify-center rounded-xl bg-light-gray-20">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M11 1.00004L1.00004 11M1 1L11 11" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </button>
        </div>
        <div class="rounded-3xl bg-light-overlay dark:bg-transparent p-4 flex flex-col gap-3 mb-5">
            <Input label="Город" v-model:value="city"/>
            <Input label="Улица, кв/дом" v-model:value="street"/>
        </div>
        <Btn :disabled="!city || !street" @click="submitAddress">Добавить</Btn>
    </div>
</template>