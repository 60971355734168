<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'

export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      isDarkMode: this.getInitialTheme(),
    };
  },
  methods: {
    getInitialTheme() {
      // Check Telegram color scheme or default to light
      if (Telegram.WebApp && Telegram.WebApp.colorScheme === 'dark') {
        return true;
      }
      return false;
    },
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('isDarkMode', this.isDarkMode);
      document.documentElement.classList.toggle('dark', this.isDarkMode);
    },
  },
  computed: {
    hideNav() {
      const excludedRoutes = [
        'filter',
        'profile-address',
        'profile-info',
        'profile-transaction',
        'order-unpaid',
        'order-id',
        'order-story',
        'order-payment',
        'order-created',
        'add-address',
        'about',
        'support-request-id',
      ];
      return !excludedRoutes.includes(this.$route.name);
    },
  },
  mounted() {
    document.documentElement.classList.toggle('dark', this.isDarkMode);
  },
};

localStorage.setItem('documentLocationHash', document.location.hash.substring(1));
</script>


<template>
  <div class="w-full h-[100svh] overflow-x-hidden dark:bg-dark-overlay font-sf-pro font-normal">
    <Header />
    <div
      class="relative z-[1] bg-light-bg dark:bg-dark-bg rounded-[24px_24px_0_0] min-h-[calc(100svh_-_117.5px)]"
      :class="{ 'pb-[72px]': hideNav }"
    >
      <div class="container">
        <router-view />
      </div>
    </div>
    <Nav v-if="hideNav" />
  </div>
</template>
