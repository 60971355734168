const state = {
    products: [],
    isLoading: false,
    error: null,
    sortBy: 'price',
    sortDirection: 'asc',
};

const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = products;
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_SORT_BY(state, sortBy) {
        state.sortBy = sortBy;
    },
    SET_SORT_DIRECTION(state, sortDirection) {
        state.sortDirection = sortDirection;
    },
};

const getters = {
    sortedProducts(state) {
      return state.products.slice().sort((a, b) => {
        if (state.sortBy === 'price') {
          return (a.price - b.price) * (state.sortDirection === 'asc' ? 1 : -1);
        } else {
          // Implement sorting logic for other criteria if needed
          return 0;
        }
      });
    },
  };

const actions = {
    setProducts({
        commit
    }, products) {
        // Your logic for processing the categories (optional)
        commit('SET_PRODUCTS', products); // Commit mutation with processed data
    },
    async fetchProducts({
        commit
    }) {
        commit('SET_LOADING', true);

        try {
            // const response = await fetch('https://megashop.gstoremobile.com/api/goods?/* your api params here */');
            const url = process.env.VUE_APP_BASE_URL + '/goods' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + document.location.hash.substring(1));
            console.log(document.location.hash);

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }
            const productsData = await response.json();
            commit('SET_PRODUCTS', productsData);
        } catch (error) {
            commit('SET_ERROR', error.message);
            console.error('Error fetching products:', error);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    sortProducts({ commit }, sortDirection) {
        commit('SET_SORT_DIRECTION', sortDirection);
    },
};


// Экспортируем наружу
export default {
    state,
    getters,
    mutations,
    actions
}