import { createStore } from 'vuex';

import products from './modules/products';
import filters from './modules/filters';


const store = createStore({
    modules: {
        products,
        filters
    }
});

export default store;