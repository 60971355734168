<script>
import { useSWR } from 'swr-vue';

export default {
    name: 'Profile',
    setup() {
        const url = process.env.VUE_APP_BASE_URL + '/me' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
        const { data, error } = useSWR(url, fetcher);

        return {
            data,
            error,
        };
    },
};

async function fetcher(url) {
    const res = await fetch(url);
    const json = await res.json();
    
    return json;
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl mb-[8px]">
            <div class="container">
                <div class="flex justify-between pt-[16px] mb-[24px]">
                    <router-link to="/profile/info" class="flex align-top gap-[16px]">
                        <img src="@/assets/images/avatar.svg" alt="">
                        <div class="flex flex-col">
                            <h6
                                class="flex items-center gap-[4px] font-bold leading-[20px] text-base text-light-main dark:text-dark-main mb-[4px] font-gilroy">
                                <template v-if="data">{{ data.first_name }} {{ data.last_name }}</template>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 11.3337L9.33333 8.00033L6 4.66699" stroke="#131313" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </h6>
                            <span class="text-description text-xs leading-[14px] font-medium font-gilroy">
                                ID: <template v-if="data">{{ data.tg_id }}</template>
                            </span>
                        </div>
                    </router-link>
                    <router-link to="/"
                        class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.35156 20.2422C9.19265 21.3114 10.514 22.0004 11.9998 22.0004C13.4856 22.0004 14.8069 21.3114 15.648 20.2422C13.2262 20.5704 10.7734 20.5704 8.35156 20.2422Z"
                                fill="#2F52F8" />
                            <path
                                d="M18.7491 9V9.7041C18.7491 10.5491 18.9903 11.3752 19.4422 12.0782L20.5496 13.8012C21.5612 15.3749 20.789 17.5139 19.0296 18.0116C14.4273 19.3134 9.57274 19.3134 4.97036 18.0116C3.21105 17.5139 2.43882 15.3749 3.45036 13.8012L4.5578 12.0782C5.00972 11.3752 5.25087 10.5491 5.25087 9.7041V9C5.25087 5.13401 8.27256 2 12 2C15.7274 2 18.7491 5.13401 18.7491 9Z"
                                fill="#2F52F8" />
                        </svg>
                    </router-link>
                </div>
                <div class="flex flex-wrap gap-[10px] pb-[16px]">
                    <router-link to="/order"
                        class="w-[calc(100%_/_3_-_7px)] bg-[#C5C3DC33] dark:bg-transparent rounded-[15px] px-[16px] py-[12px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.74984 2.5C7.74984 2.08579 7.41405 1.75 6.99984 1.75C6.58563 1.75 6.24984 2.08579 6.24984 2.5V4.07926C4.81051 4.19451 3.86561 4.47737 3.17141 5.17157C2.47721 5.86577 2.19435 6.81067 2.0791 8.25H21.9205C21.8053 6.81067 21.5224 5.86577 20.8282 5.17157C20.134 4.47737 19.1891 4.19451 17.7498 4.07926V2.5C17.7498 2.08579 17.414 1.75 16.9998 1.75C16.5856 1.75 16.2498 2.08579 16.2498 2.5V4.0129C15.5845 4 14.8388 4 13.9998 4H9.99984C9.16081 4 8.41511 4 7.74984 4.0129V2.5Z"
                                fill="#2F52F8" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12C2 11.161 2 10.4153 2.0129 9.75H21.9871C22 10.4153 22 11.161 22 12ZM16.5 18C17.3284 18 18 17.3284 18 16.5C18 15.6716 17.3284 15 16.5 15C15.6716 15 15 15.6716 15 16.5C15 17.3284 15.6716 18 16.5 18Z"
                                fill="#2F52F8" />
                        </svg>
                        <h6
                            class="text-xs font-medium text-light-main dark:text-dark-main mt-[12px] leading-[14.12px] font-gilroy">
                            История заказов
                        </h6>
                    </router-link>
                    <router-link to="/profile/transaction"
                        class="w-[calc(100%_/_3_-_7px)] bg-[#C5C3DC33] dark:bg-transparent rounded-[15px] px-[16px] py-[12px]">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.6667 4H14.6667C18.4379 4 20.3236 4 21.4951 5.17157C22.3387 6.01511 22.5749 7.22882 22.641 9.25H2.69238C2.75851 7.22882 2.99469 6.01511 3.83823 5.17157C5.00981 4 6.89542 4 10.6667 4Z"
                                fill="#2F52F8" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M22.666 12.8175L22.2575 12.409C21.3788 11.5303 19.9542 11.5303 19.0755 12.409L18.2741 13.2104C17.9543 12.3573 17.1313 11.75 16.1665 11.75C14.9239 11.75 13.9165 12.7574 13.9165 14V15.7638C13.4266 15.8183 12.9512 16.0334 12.5755 16.409C11.6968 17.2877 11.6968 18.7123 12.5755 19.591L12.9845 20H10.6665C6.89526 20 5.00965 20 3.83807 18.8284C2.6665 17.6569 2.6665 15.7712 2.6665 12C2.6665 11.5581 2.6665 11.142 2.66839 10.75H22.6646C22.6665 11.142 22.6665 11.5581 22.6665 12C22.6665 12.283 22.6665 12.5553 22.666 12.8175ZM6.6665 15.25C6.25229 15.25 5.9165 15.5858 5.9165 16C5.9165 16.4142 6.25229 16.75 6.6665 16.75H10.6665C11.0807 16.75 11.4165 16.4142 11.4165 16C11.4165 15.5858 11.0807 15.25 10.6665 15.25H6.6665Z"
                                fill="#2F52F8" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16.1665 13.25C16.5807 13.25 16.9165 13.5858 16.9165 14V18.1893L17.6362 17.4697C17.9291 17.1768 18.4039 17.1768 18.6968 17.4697C18.9897 17.7626 18.9897 18.2374 18.6968 18.5303L16.6968 20.5303C16.4039 20.8232 15.9291 20.8232 15.6362 20.5303L13.6362 18.5303C13.3433 18.2374 13.3433 17.7626 13.6362 17.4697C13.9291 17.1768 14.4039 17.1768 14.6968 17.4697L15.4165 18.1893V14C15.4165 13.5858 15.7523 13.25 16.1665 13.25ZM20.1362 13.4697C20.4291 13.1768 20.9039 13.1768 21.1968 13.4697L23.1968 15.4697C23.4897 15.7626 23.4897 16.2374 23.1968 16.5303C22.9039 16.8232 22.4291 16.8232 22.1362 16.5303L21.4165 15.8107V20C21.4165 20.4142 21.0807 20.75 20.6665 20.75C20.2523 20.75 19.9165 20.4142 19.9165 20V15.8107L19.1968 16.5303C18.9039 16.8232 18.4291 16.8232 18.1362 16.5303C17.8433 16.2374 17.8433 15.7626 18.1362 15.4697L20.1362 13.4697Z"
                                fill="#2F52F8" />
                        </svg>
                        <h6
                            class="text-xs font-medium text-light-main dark:text-dark-main mt-[12px] leading-[14.12px] font-gilroy">
                            История
                            транзакций
                        </h6>
                    </router-link>
                    <router-link to="/profile/address"
                        class="w-[calc(100%_/_3_-_7px)] bg-[#C5C3DC33] dark:bg-transparent rounded-[15px] px-[16px] py-[12px]">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.3335 2C7.91522 2 4.3335 6.00258 4.3335 10.5C4.3335 14.9622 6.88682 19.8124 10.8706 21.6744C11.7992 22.1085 12.8678 22.1085 13.7964 21.6744C17.7802 19.8124 20.3335 14.9622 20.3335 10.5C20.3335 6.00258 16.7518 2 12.3335 2ZM12.3335 12C13.4381 12 14.3335 11.1046 14.3335 10C14.3335 8.89543 13.4381 8 12.3335 8C11.2289 8 10.3335 8.89543 10.3335 10C10.3335 11.1046 11.2289 12 12.3335 12Z"
                                fill="#2F52F8" />
                        </svg>
                        <h6
                            class="text-xs font-medium text-light-main dark:text-dark-main mt-[12px] leading-[14.12px] font-gilroy">
                            Мои <br>
                            адреса
                        </h6>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[16px]">
            <h3 class="font-bold font-gilroy text-2xl leading-[24px] text-light-main dark:text-dark-main mb-[16px]">
                Активные заказы
            </h3>
            <div class="p-[16px] rounded-3xl bg-light-bg dark:bg-transparent block mb-[16px]">
                <div class="flex gap-[8px] w-full pb-[15px]">
                    <div class="p-[5px] relative rounded-[8px] bg-white w-[65px] h-[70px] flex-shrink-0">
                        <img src="@/assets/images/product_img.png" alt="">
                        <span
                            class="absolute left-0 top-0 flex items-center justify-center font-bold font-gilroy text-xs leading-[14px] text-white rounded-full bg-accent w-[23px] h-[23px]">
                            1
                        </span>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-between mb-[14px]">
                            <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy">
                                Заказ №701
                            </h4>
                            <h4
                                class="font-bold font-gilroy text-[12px] leading-[14px] px-[8px] py-[4px] rounded-[20px] bg-attention text-white dark:text-dark-overlay">
                                В ожидании оплаты
                            </h4>
                        </div>
                        <h6
                            class="font-gilroy font-bold text-xs leading-[14px] text-black dark:text-dark-main mb-[4px]">
                            Доставка 28 июля
                        </h6>
                        <p class="font-gilroy font-bold text-xs leading-[14px] text-description">
                            просп. Суюнбая 663, Алматы, Казахстан
                        </p>
                    </div>
                </div>
                <div class="border-t dark:border-t-light-gray-20 flex justify-between items-center pt-[12px]">
                    <router-link to="/order/121312" class="text-accent flex items-center gap-[8px]">
                        Подробнее
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                    <div class="flex items-center gap-[7px]">
                        <h4
                            class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy flex items-center">
                            2 343
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z"
                                    fill="#50AF95" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z"
                                    fill="white" />
                            </svg>
                        </h4>
                        <h5 class="font-gilroy font-medium text-[12px] leading-[14px] text-description">
                            ~ 786 833,35 ₸
                        </h5>
                    </div>
                </div>
            </div>
            <div class="p-[16px] rounded-3xl bg-light-bg dark:bg-transparent block">
                <div class="flex gap-[8px] w-full pb-[15px]">
                    <div class="p-[5px] relative rounded-[8px] bg-white w-[65px] h-[70px] flex-shrink-0">
                        <img src="@/assets/images/product_img2.png" alt="">
                        <span
                            class="absolute left-0 top-0 flex items-center justify-center font-bold font-gilroy text-xs leading-[14px] text-white rounded-full bg-accent w-[23px] h-[23px]">
                            5
                        </span>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-between mb-[14px]">
                            <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy">
                                Заказ №700
                            </h4>
                            <h4
                                class="font-bold font-gilroy text-[12px] leading-[14px] px-[8px] py-[4px] rounded-[20px] bg-accent text-white">
                                Отправлен
                            </h4>
                        </div>
                        <h6
                            class="font-gilroy font-bold text-xs leading-[14px] text-black dark:text-dark-main mb-[4px]">
                            Самовывоз со склада 17 июля
                        </h6>
                        <p class="font-gilroy font-bold text-xs leading-[14px] text-description">
                            улица Жеруйык 2, Алматы, Казахстан
                        </p>
                    </div>
                </div>
                <div class="border-t dark:border-t-light-gray-20 flex justify-between items-center pt-[12px]">
                    <router-link to="/order/213123" class="text-accent flex items-center gap-[8px]">
                        Подробнее
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                    <div class="flex items-center gap-[7px]">
                        <h4
                            class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy flex items-center">
                            2 343
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z"
                                    fill="#50AF95" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z"
                                    fill="white" />
                            </svg>
                        </h4>
                        <h5 class="font-gilroy font-medium text-[12px] leading-[14px] text-description">
                            ~ 786 833,35 ₸
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <!-- Empty order -->
        <!-- <div class="bg-light-overlay rounded-3xl p-[16px] mb-[16px] flex flex-col items-center text-center">
            <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44.2501 37.5L31.7501 50M31.75 37.5L44.2501 50" stroke="#C5C3DC" stroke-opacity="0.8" stroke-width="4.6875" stroke-linecap="round"/>
                <path d="M28.6246 18.75V15.625C28.6246 10.4473 32.8218 6.25 37.9996 6.25C43.1774 6.25 47.3746 10.4473 47.3746 15.625V18.75M12.1927 64.2328C15.9415 68.75 22.9188 68.75 36.873 68.75H39.1268C53.0811 68.75 60.0586 68.75 63.8074 64.2328C67.5561 59.7159 66.2702 52.8581 63.6986 39.1428C61.8699 29.3891 60.9555 24.5123 57.4839 21.6311C54.0124 18.75 49.0505 18.75 39.1268 18.75H36.873C26.9495 18.75 21.9877 18.75 18.5161 21.6311C15.0446 24.5123 14.1302 29.3891 12.3014 39.1428C9.72973 52.8581 8.44391 59.7159 12.1927 64.2328Z" stroke="#C5C3DC" stroke-opacity="0.8" stroke-width="4.6875" stroke-linecap="round"/>
            </svg>
            <h6 class="font-gilroy font-bold text-[20px] leading-[24.18px] mt-[30px]">
                Нет активных заказов
            </h6>
            <p class="mb-[30px] font-gilroy font-medium text-base leading-[20px] text-description">
                Добавьте товары и оформите<br>
                заказ из корзины
            </p>
            <router-link to="/" class="pb-[20px]">
                <Btn to="/" class="w-[140px]">
                    На главную
                </Btn>
            </router-link>

        </div> -->
        <!-- Empty order end -->
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px]">
            <h3 class="font-bold font-gilroy text-2xl leading-[24px] text-light-main dark:text-dark-main mb-[16px]">
                Информация
            </h3>
            <ul>
                <li class="border-b border-solid border-[#C5C3DC33] dark:border-light-gray-20">
                    <router-link to="/"
                        class="py-[16px] flex justify-between items-center font-gilroy font-medium text-[16px] leading-[20px] text-black cursor-pointer dark:text-dark-main">
                        Гарантия
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
                <li class="border-b border-solid border-[#C5C3DC33] dark:border-light-gray-20">
                    <router-link to="/"
                        class="py-[16px] flex justify-between items-center font-gilroy font-medium text-[16px] leading-[20px] text-black cursor-pointer dark:text-dark-main">
                        Компенсация
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
                <li class="border-b border-solid border-[#C5C3DC33] dark:border-light-gray-20">
                    <router-link to="/"
                        class="py-[16px] flex justify-between items-center font-gilroy font-medium text-[16px] leading-[20px] text-black cursor-pointer dark:text-dark-main">
                        Договор оферты
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
                <li>
                    <router-link to="/about"
                        class="pt-[16px] pb-[8px] flex justify-between items-center font-gilroy font-medium text-[16px] leading-[20px] text-black dark:text-dark-main cursor-pointer">
                        О нас
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699"
                                stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
