<script>
import { useSWR } from 'swr-vue';
import { ref } from 'vue';

export default {
    name: "Order payment",
    setup() {
        const addressList = getAddresses();
        const tab = ref(1);
        const addressSelect = ref(1);
        const basketCard = [1, 2, 3];
        const check = false;

        return {
            tab,
            addressList,
            addressSelect,
            basketCard,
            check,
        };
    },
}

function getAddresses() {
    const { data, error } = useSWR('/addresses', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div class="font-gilroy pb-[11px]">
        <div class="relative">
            <h3 class="text-center py-[29px] text-light-main dark:text-dark-main font-bold text-xl leading-6">Оплата заказа</h3>
            <router-link to="/basket" class="absolute right-0 top-1/2 translate-y-[-50%] w-10 h-10 flex items-center justify-center rounded-xl bg-light-gray-20">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M11 1.00004L1.00004 11M1 1L11 11" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </router-link>
        </div>
        <div class="flex flex-col gap-2">
            <div class="p-4 rounded-3xl bg-light-overlay dark:bg-transparent">
                <h3 class="text-light-main dark:text-dark-main text-xl font-bold leading-6 mb-4">Способ получения</h3>
                <div class="bg-light-gray-20 rounded-full flex items-stretch">
                    <button 
                        class="w-full h-10 text-xs font-bold font-gilroy text-light-main dark:text-dark-main rounded-full"
                        :class="{
                            'bg-light-main dark:bg-dark-main text-light-overlay dark:text-dark-overlay': tab == 1,
                        }"
                        @click="tab = 1"
                    >Самовывоз</button>
                    <button 
                        class="w-full h-10 text-xs font-bold font-gilroy text-light-main dark:text-dark-main rounded-full"
                        :class="{
                            'bg-light-main dark:bg-dark-main text-light-overlay dark:text-dark-overlay': tab == 2,
                        }"
                        @click="tab = 2"
                    >Доставка</button>
                </div>

                <!-- Tab 1 -->
                <div v-if="tab == 1">
                    <img src="@/assets/images/map.png" alt="" class="my-4">
                    <div class="flex items-start gap-[10px]">
                        <div class="w-6 h-6 flex items-center justify-center">
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C3.58172 0 0 4.00258 0 8.5C0 12.9622 2.55332 17.8124 6.5371 19.6744C7.4657 20.1085 8.5343 20.1085 9.4629 19.6744C13.4467 17.8124 16 12.9622 16 8.5C16 4.00258 12.4183 0 8 0ZM8 10C9.1046 10 10 9.1046 10 8C10 6.89543 9.1046 6 8 6C6.8954 6 6 6.89543 6 8C6 9.1046 6.8954 10 8 10Z" fill="#C5C3DC" fill-opacity="0.8"/>
                            </svg>
                        </div>
                        <div>
                            <p class="text-light-main dark:text-dark-main text-base font-bold leading-5 mb-1">Самовывоз со склада 17 июля</p>
                            <p class="text-description text-balance font-medium leading-5">улица Жеруйык 2, Алматы, Казахстан</p>
                        </div>
                    </div>
                </div>
                <!-- Tab 1 end -->

                <!-- Tab 2 -->
                <div v-if="tab == 2">
                    <ul class="flex flex-col gap-4 my-4">
                        <li
                            v-for="(address, i) in addressList"
                            :key="i"
                            @click="addressSelect = i+1"
                            class="flex items-start gap-[10px] cursor-pointer select-none"
                        >
                            <button
                                class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                                :class="addressSelect == i+1 ? 'bg-accent' : 'bg-light-gray-20'"
                            >
                                <span v-if="addressSelect == i+1" class="bg-white-accent dark:bg-dark-overlay dark:border dark:border-white w-2 h-2 rounded-full block"></span>
                            </button>
                            <div class="w-full">
                                <h4 class="text-light-main dark:text-dark-main font-bold text-balance leading-5 mb-1">Доставка 28 июля</h4>
                                <p class="text-description text-base leading-5 font-medium">{{ address.city }}, {{ address.info }}</p>
                            </div>
                        </li>
                        <!-- <li>
                            <img src="@/assets/images/map.png" alt="" class="mb-4">
                            <div @click="addressSelect = 3" class="flex items-start gap-[10px] cursor-pointer select-none">
                                <button
                                    class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                                    :class="addressSelect == 3 ? 'bg-accent' : 'bg-light-gray-20'"
                                >
                                    <span v-if="addressSelect == 3" class="bg-white-accent dark:bg-dark-overlay dark:border dark:border-white w-2 h-2 rounded-full block"></span>
                                </button>
                                <div class="w-full">
                                    <h4 class="text-light-main dark:text-dark-main font-bold text-balance leading-5 mb-1">Доставка 28 июля</h4>
                                    <p class="text-description text-base leading-5 font-medium">улица Зейна Шашкина 11, 21, Алматы, Казахстан</p>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <Btn @click="$router.push({name: 'add-address'})" theme="light">Добавить адрес</Btn>
                </div>
                <!-- Tab 2 end -->
            </div>
            <div class="rounded-3xl p-4 bg-light-overlay dark:bg-transparent">
                <h3 class="text-light-main dark:text-dark-main text-xl font-bold leading-6 mb-6">Товары к оплате</h3>
                <ul>
                    <li
                        v-for="(card, i) in basketCard"
                        :key="i"
                        class="flex items-start gap-6"
                        :class="{
                            'pb-6 mb-6 border-b border-b-light-gray-20': basketCard.length != (i + 1)
                        }"
                    >
                        <div class="w-[65px] flex-shrink-0">
                            <img src="@/assets/images/basket-card.png" class="w-full" alt="">
                        </div>
                        <div class="w-full">
                            <h4 class="font-medium text-base leading-5 text-light-main dark:text-dark-main mb-1">iPhone 15 Pro Max</h4>
                            <p class="text-description font-medium text-xs leading-[14px] mb-3">256 GB, Blue Titanium, Индия</p>
                            <div class="flex items-center justify-between">
                                <div class="flex items-center gap-2">
                                    <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">1 674</span>
                                    <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                                </div>
                                <p class="text-description font-medium text-xs">~ 423 358,35 ₸</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="rounded-3xl p-4 bg-light-overlay dark:bg-transparent">
                <div class="flex items-start gap-4">
                    <Checkbox v-model:value="check" />
                    <div class="w-full">
                        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5 mb-2">Добавить гарантию 1 год</p>
                        <div class="flex items-center gap-[15px]">
                            <h4 class="flex items-center gap-2">
                                <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">6</span>
                                <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                            </h4>
                            <p class="text-description font-medium text-xs">~ 475,53 ₸</p>
                        </div>
                    </div>
                </div>
                <div class="h-[1px] bg-light-gray-20 my-4"></div>
                <div class="flex items-start justify-between">
                    <p class="text-light-main dark:text-dark-main font-medium text-base leading-5">Общая стоимость:</p>
                    <div class="flex flex-col items-end gap-3">
                        <h4 class="flex items-center gap-2">
                            <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">1 674</span>
                            <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                        </h4>
                        <p class="text-description font-medium text-xs">~ 423 358,35 ₸</p>
                    </div>
                </div>
                <Btn @click="$router.push({name: 'order-created'})" class="my-4">Оплатить</Btn>
                <p class="text-center text-attention text-xs font-medium">Внимание, к оплате принимаются только токены USDT <br> на TRC-20</p>
            </div>
        </div>
    </div>
</template>