const state = {
    filtersData: [],
    activeCategoryId: null,
};

const getters = {};

const mutations = {
    SET_ACTIVE_CATEGORY_ID(state, id) {
        state.activeCategoryId = id;
    },
    SET_CATEGORIES_WITH_BRANDS(state, filtersData) {
        state.filtersData = filtersData;
    },
};

const actions = {
    applyFilter({
        state,
        rootState
    }) {
        rootState.products.products.forEach((product) => {
            let isHidden = true; // Assume product is initially hidden

            if (product.category_id === state.activeCategoryId) {
                isHidden = false; // Show product if category matches
            }

            // Brand filtering
            const selectedBrandIds = state.filtersData.find((category) => category.id === state.activeCategoryId)?.brands
                .filter((brand) => brand.selected)
                .map((brand) => brand.id) || [];

            if (selectedBrandIds.length && !selectedBrandIds.includes(product.brand_id)) {
                isHidden = true; // Hide if not in selected brands
            }

            // Model filtering
            const selectedModelTitles = state.filtersData
                .find((category) => category.id === state.activeCategoryId)?.models
                .filter((model) => model.selected)
                .map((model) => model.title) || [];

            if (selectedModelTitles.length && !selectedModelTitles.includes(product.model)) {
                isHidden = true; // Hide if not in selected models
            }

            product.hide = isHidden; // Update product's hidden state
        });
    },
    toggleFilterBrand({
        commit
    }, brand) {
        brand.selected = !brand.selected;
        let selectedBrands = [];
        state.filtersData.forEach((category) => {
            if (state.activeCategoryId == category.id) {
                category.brands.forEach((categoryBrand) => {
                    if (categoryBrand.selected) {
                        selectedBrands.push(categoryBrand.id);
                    }
                })
                if (selectedBrands.length) {
                    category.models.forEach((model) => {
                        if (selectedBrands.indexOf(model.brand_id) >= 0) {
                            model.is_hide = false;
                        } else {
                            model.is_hide = true;
                        }
                    })
                } else {
                    category.models.forEach((model) => {
                        model.is_hide = false;
                    })
                }
            }
        })
    },
    toggleFilterModel({
        commit
    }, model) {
        model.selected = !model.selected;
        let selectedModels = [];
        state.filtersData.forEach((category) => {
            if (state.activeCategoryId == category.id) {
                category.models.forEach((categoryModel) => {
                    if (categoryModel.selected) {
                        selectedModels.push(categoryModel.id);
                    }
                })
                if (!selectedModels.length) {
                    category.models.forEach((model) => {
                        model.selected = false;
                    })
                }
            }
        })
    },
    setActiveCategoryId({
        commit
    }, id) {
        commit('SET_ACTIVE_CATEGORY_ID', id); // Commit mutation with processed data
    },
    setFiltersData({
        commit
    }, filtersData) {
        commit('SET_CATEGORIES_WITH_BRANDS', filtersData); // Commit mutation with processed data
    },
    getFiltersData({
        rootState,
        commit
    }) {
        const categories = rootState.products.products.reduce((acc, product) => {
            if (!product.category || !product.brand) {
                return acc; // Skip products without category or brand
            }

            const existingCategory = acc.find(c => c.id === product.category.id);
            if (existingCategory) {
                const existingBrand = existingCategory.brands.find(brand => brand.id === product.brand.id);
                if (!existingBrand) {
                    existingCategory.brands.push({
                        id: product.brand.id,
                        title: product.brand.title,
                        selected: false, // Add `selected` property for brand filtering
                    });
                }
                
                // Ensure `models` is an array before using find
                if (!Array.isArray(existingCategory.models)) {
                    existingCategory.models = [];
                }

                // Add unique model to category's `models` array (if not already present and not null)
                if (!existingCategory.models || !existingCategory.models.includes(product.model) && product.model !== null) {
                    if (!existingCategory.models) {
                        existingCategory.models = [];
                    }
                    // Проверка на существование модели и добавление с необходимыми свойствами
                    const existingModel = existingCategory.models.find(model => model.title === product.model);
                    if (!existingModel && product.model !== null) {
                        existingCategory.models.push({
                            title: product.model,
                            brand_id: product.brand.id,
                            is_hide: false,
                            selected: false
                        });
                    }
                }
            } else {
                acc.push({
                    id: product.category.id,
                    title: product.category.title,
                    brands: [{
                        id: product.brand.id,
                        title: product.brand.title,
                        selected: false, // Add `selected` property for brand filtering
                    }],
                    models: product.model, // Initialize models array with non-null model
                });
            }

            return acc;
        }, []);

        commit('SET_CATEGORIES_WITH_BRANDS', categories);
    },
};

// Экспортируем наружу
export default {
    state,
    getters,
    mutations,
    actions
}