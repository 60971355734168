<script>
export default {
    props: {
        product: Object,
    },
    data() {
        return {
            calculation: null,
        };
    },
    methods: {
        getTruncatedTitle(title) {
            return title.length > 19 ? title.slice(0, 16) + "..." : title;
        },
        formattedPrice(price) {
            return price.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    },
    computed: {
        progressWidth() {
            if (this.product.count >= 100) {
                return '100%';
            } else {
                return `${this.product.count}%`;
            }
        },
        progressBarGradient() {
            return `hsl(${this.product.count}, 100%, 50%)`;
        },
    },
};
</script>

<template>
    <li class="w-[calc(50%_-_10px)] bg-light-overlay dark:bg-transparent rounded-3xl pb-4">
        <div class="mb-2 h-[169px] flex items-center justify-center dark:bg-white dark:rounded-[20px] p-5">
            <img v-if="product.images[0]" :src="product.images[0].resize_image_url" alt=""
                class="object-contain h-[169px]">
        </div>
        <div class="px-5 flex flex-col justify-between">
            <h4
                class="flex items-center gap-2 font-gilroy font-bold text-base leading-5 mb-[2px text-light-main dark:text-dark-main">
                <span>{{ product.price }}</span>
                <img src="@/assets/images/tether.svg" alt="">
            </h4>
            <!-- <p class="text-description font-gilroy font-medium text-sm mb-4">~ {{ formattedPrice(product.price * 479.23)
                }} ₸</p> -->
            <h3 class="font-gilroy text-lg font-bold mb-2 text-light-main dark:text-dark-main">{{ product.title }}</h3>
            <p class="text-description font-gilroy text-sm mb-3">
                <template v-if="product.short_description">
                    {{ product.short_description }}
                </template>
            </p>
            <div class="mb-4">
                <p class="text-light-main dark:text-dark-main mb-1 font-gilroy text-xs font-medium">Осталось {{
                    product.count }} шт</p>
                <div class="w-full h-[3px] rounded-full overflow-hidden bg-light-gray-20 relative">
                    <span class="absolute top-0 left-0 h-full rounded-full"
                        :style="{ width: progressWidth, background: progressBarGradient }"></span>
                </div>
            </div>
        </div>
        <div class="px-5">
            <div v-if="calculation == product.id"
                class="flex items-center justify-between bg-light-gray-20 rounded-xl p-[5px]">
                <button @click="product.count != 1 ? product.count++ : product.count = product.count"
                    class="bg-accent w-[30px] h-[30px] flex items-center justify-center flex-shrink-0 rounded-[7px]">
                    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1H1" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </button>
                <p class="text-light-main dark:text-dark-main font-gilroy font-bold text-base leading-5">
                    {{ product.count }}</p>
                <button @click="product.count--"
                    class="bg-accent w-[30px] h-[30px] flex items-center justify-center flex-shrink-0 rounded-[7px]">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6H6M6 6H1M6 6V1M6 6V11" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </button>
            </div>
            <button v-else @click="calculation = product.id"
                class="bg-accent w-full flex items-center justify-center gap-2 py-4 rounded-xl">
                <span class="font-gilroy font-bold text-base leading-4 text-white-accent">17 июля</span>
                <img src="@/assets/images/basket-light.svg" alt="">
            </button>
        </div>
    </li>
</template>